{
  "search": "Rechercher",
  "search_no_results_1": "Oh non!",
  "search_no_results_2": "Cet emoji ne peut être trouvé",
  "pick": "Choisissez un emoji…",
  "add_custom": "Ajouter un emoji personnalisé",
  "categories": {
    "activity": "Activités",
    "custom": "Personnalisé",
    "flags": "Drapeaux",
    "foods": "Nourriture & Boissons",
    "frequent": "Fréquemment utilisé",
    "nature": "Animaux & Nature",
    "objects": "Objets",
    "people": "Smileys & Personnes",
    "places": "Voyages & Lieux",
    "search": "Résultats de recherche",
    "symbols": "Symboles"
  },
  "skins": {
    "choose": "Choisissez une couleur de peau",
    "1": "Défaut",
    "2": "Clair",
    "3": "Moyen-clair",
    "4": "Moyen",
    "5": "Moyen-foncé",
    "6": "Foncé"
  }
}
